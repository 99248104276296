import React from 'react';

export default function Label(props) {

   const { id, label = null, line = "true" } = props;

   var classContainer = null;
   var classLabel = 'col-sm-12 col-form-label';
   var classDiv = 'col-sm-12';

   if(props.col){
      if(label !== null && line === "true"){
         const divMd = props.col;
         const labelMd = props.collabel ?? (12 - divMd);

         classLabel = `col-md-${labelMd} col-sm-12 col-form-label col-ok1`;
         classDiv = `col-md-${divMd} col-sm-12 col-ok1`;

      }else if(label !== null)
         classContainer = `col-md-${props.col} col-sm-12 col-ok2`;
      else classDiv = `col-md-${props.col} col-sm-12 col-ok3`;
   }else{
      if(label !== null && line === "true"){
         classLabel = 'col-lg-2 col-md-3 col-sm-12 col-form-label';
         classDiv = 'col-lg-10 col-md-9 col-sm-12 col-form-label';

      }else{
         classLabel = 'col-12 col-form-label';
         classDiv = 'col-12'
      }
   }

   if(label === null){
      return (
         <div className={classDiv}>
				{props.children}
			</div>
      );
   }else{

      if(classContainer === null)
         return   (<>
                     <label className={classLabel} htmlFor={id}>{label}</label>
                     <div className={classDiv}>
                        {props.children}
                     </div>
                  </>)
      else
         return   (<div className={classContainer}>
                     <label className={classLabel} htmlFor={id}>{label}</label>
                     <div className={classDiv}>
                        {props.children}
                     </div>
                  </div>)    
   }
}