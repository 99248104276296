import { consultar } from "helpers/api";
import { AppHelper } from "helpers/AppHelper";

const controller = "clientes";

export const ClientesService = {
	buscar,
	getAll,
	getById,
	create,
	update,
	delete: _delete,
};

async function buscar(busca) {
	return await consultar(
		`/${controller}/buscar/${AppHelper.getEmpresaAtivaId()}.json`,
		"put",
		{ busca: busca }
	);
}

async function getAll(page, busca = null) {
	var _url = AppHelper.mountUrl(
		`/${controller}/getAll/${AppHelper.getEmpresaAtivaId()}.json`,
		{ page: page, busca: busca }
	);

	// var _url = `/${controller}/getAll/${AppHelper.getEmpresaAtivaId()}.json`;
	//if (page) _url += `?page=${page}`;

	console.log(`URL ${_url}`);
	return await consultar(_url, "get");
}

async function getById(id) {
	return await consultar(`/${controller}/${id}.json`);
}

async function create(params) {
	return await consultar(`/${controller}.json`, "post", params);
}

async function update(id, params) {
	return await consultar(`/${controller}/${id}.json`, "put", params);
}

// prefixed with underscored because delete is a reserved word in javascript
async function _delete(id) {
	return await consultar(`/${controller}/${id}.json`, "delete");
}

// Gerado pelo gerador de SERVICES @felipeshow (21:03:43 11/06/2021)
