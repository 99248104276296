import { consultar } from 'helpers/api';

const controller = "bancos";

export const BancosService = {
	list
};

async function list() {
	return await consultar(`/${controller}/list.json`);
}