import React from 'react';
import Label from './Label'

export default function InputCheckbox(props) {

   const { id, label = '', className = 'form-check-input', value, onChange, register, ...other } = props;
   
   return   <Label label={label} id={id} {...other}>
               <input type="checkbox" className={className} id={id} name={id} {...other} {...register(id)}></input>
            </Label>
}