import { consultar } from 'helpers/api';
import { AppHelper } from 'helpers/AppHelper';

const controller = "contratos";

export const ContratosService = {
   buscar,
	ultimos,
	getAll,
	getById,
	create,
	cancel,
	gerarAditivo
};

async function buscar(busca) {
	return await consultar(`/${controller}/buscar/${AppHelper.getEmpresaAtivaId()}.json`, 'put', { 'busca' : busca });
}

async function ultimos(empresaId){
	return await consultar(`/${controller}/getUltimosContratos/${empresaId}.json`, 'get');
}

async function getAll(page) {
	var _url = `/${controller}/getAll/${AppHelper.getEmpresaAtivaId()}.json`
	if(page)_url += `?page=${page}`;

	return await consultar(_url);
}

async function getById(id) {
	return await consultar(`/${controller}/${id}.json`);
}

async function create(params) {
	params.empresa_id = AppHelper.getEmpresaAtivaId();

	return await consultar(`/${controller}.json`, 'post', params);
}

async function cancel(id, params) {
	return await consultar(`/${controller}/cancelarContrato/${id}.json`, 'put', params);
}

async function gerarAditivo(id, params) {
	return await consultar(`/${controller}/gerarAditivo/${id}.json`, 'post', params);
}

// Gerado pelo gerador de SERVICES @felipeshow (20:41:17 18/01/2022)