import { consultar } from 'helpers/api';
import { AppHelper } from 'helpers/AppHelper';

const controller = "contratos-tipos";

export const ContratosTiposService = {
	getAll,
	getById,
	create,
	update,
	delete: _delete,
	getServicosList
};

async function getAll(page) {
	var _url = `/${controller}/getAll/${AppHelper.getEmpresaAtivaId()}.json`
	if(page)_url += `?page=${page}`;

	return await consultar(_url);
}

async function getById(id) {
	return await consultar(`/${controller}/${id}.json`);
}

async function create(params) {
	return await consultar(`/${controller}.json`, 'post', params);
}

async function update(id, params) {
	return await consultar(`/${controller}/${id}.json`, 'put', params);
}

// prefixed with underscored because delete is a reserved word in javascript
async function _delete(id) {
	return await consultar(`/${controller}/${id}.json`, 'delete');
}

async function getServicosList(id){
	return await consultar(`/${controller}/getServicosList/${id}.json`);
}

// Gerado pelo gerador de SERVICES @felipeshow (20:41:17 18/01/2022)