import { consultar } from 'helpers/api';

const controller = "cr-config-duplicata";

export const CrConfigDuplicataService = {
	getAll,
	getById,
	create,
	update,
	delete: _delete
};

async function getAll(page) {
	var _url = `/${controller}/list.json`
	if(page)_url += `?page=${page}`;

	return await consultar(_url);
}

async function getById(id) {
	return await consultar(`/${controller}\\${id}.json`);
}

async function create(params) {
	return await consultar(`/${controller}.json`, 'post', params);
}

async function update(id, params) {
	return await consultar(`/${controller}\\${id}.json`, 'put', params);
}

// prefixed with underscored because delete is a reserved word in javascript
async function _delete(id) {
	return await consultar(`/${controller}\\${id}.json`, 'delete');
}

// Gerado pelo gerador de SERVICES @felipeshow (17:10:17 20/05/2022)