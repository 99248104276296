import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { alertService, AlertType } from 'services';

const propTypes = {
   id: PropTypes.string,
   fade: PropTypes.bool,
   closeButton: PropTypes.bool
};

const defaultProps = {
   id: 'default-alert',
   fade: true,
   closeButton: true
};

// Exibicao de Alertas atraves do alert.service.
// Modo de USO
// Adiciona o Alert, atraves do "import { Alert } from 'components/elements/Alert';"
// e inclui o TAG <Alert /> no local onde os alertas devem aparecer.
// Adiciona o service "import { alertService, EmpresasService } from 'services';"
// e faz a chamada como no exemplo: alertService.success("teste");

function Alert({ id, fade }) {
   const history = useHistory();
   const [alerts, setAlerts] = useState([]);

   useEffect(() => {
      // subscribe to new alert notifications
      const subscription = alertService.onAlert(id)
         .subscribe(alert => {
            // clear alerts when an empty alert is received
            if (!alert.message) {
               setAlerts(alerts => {
                  // filter out alerts without 'keepAfterRouteChange' flag
                  const filteredAlerts = alerts.filter(x => x.keepAfterRouteChange);

                  // remove 'keepAfterRouteChange' flag on the rest
                  filteredAlerts.forEach(x => delete x.keepAfterRouteChange);
                  return filteredAlerts;
               });
            } else {
               // add alert to array
               setAlerts(alerts => ([...alerts, alert]));

               // auto close alert if required
               if (alert.autoClose)setTimeout(() => removeAlert(alert), 3000);
            }
         });

      // clear alerts on location change
      const historyUnlisten = history.listen(({ pathname }) => {
         // don't clear if pathname has trailing slash because this will be auto redirected again
         if (pathname.endsWith('/')) return;

         alertService.clear(id);
      });

      // clean up function that runs when the component unmounts
      return () => {
         // unsubscribe & unlisten to avoid memory leaks
         subscription.unsubscribe();
         historyUnlisten();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   function removeAlert(alert) {
      if (fade) {
         // fade out alert
         const alertWithFade = { ...alert, fade: true };
         setAlerts(alerts => alerts.map(x => x === alert ? alertWithFade : x));

         // remove alert after faded out
         setTimeout(() => setAlerts(alerts => alerts.filter(x => x !== alertWithFade)), 250);
      } else {
         // remove alert
         setAlerts(alerts => alerts.filter(x => x !== alert));
      }
   }

   function cssClasses(alert) {
      if (!alert) return;

      const classes = ['alert', 'alert-dismissable', 'd-flex'];

      const alertTypeClass = {
         [AlertType.Success]: 'alert alert-success',
         [AlertType.Error]: 'alert alert-danger',
         [AlertType.Info]: 'alert alert-info',
         [AlertType.Warning]: 'alert alert-warning'
      }

      classes.push(alertTypeClass[alert.type]);

      if (alert.fade) {
         classes.push('fade');
      }

      return classes.join(' ');
   }

   function iconCssClasses(alert) {
      if (!alert) return;

      const classes = ['fas', 'mt-1'];

      const alertTypeClass = {
         [AlertType.Success]: 'fa-check',
         [AlertType.Error]: 'fa-times',
         [AlertType.Info]: 'fa-info',
         [AlertType.Warning]: 'fa-exclamation'
      }

      classes.push(alertTypeClass[alert.type]);

      return classes.join(' ');
   }

   if (!alerts.length) return null;
console.log(alerts);

   return (
      <div className="row">
         {alerts.map((alert, index) =>
            <div key={index} className="col-12">
               <div className={cssClasses(alert)}>
                  <i className={iconCssClasses(alert)}></i>&nbsp;
                  <p className="mb-0 ml-1 flex-1" dangerouslySetInnerHTML={{ __html: alert.message }}></p>
                  {(alert.canClose) &&
                     <button className="btn-close close" onClick={() => removeAlert(alert)}></button>
                  }
               </div>
            </div>
         )}
      </div>
   );
}

Alert.propTypes = propTypes;
Alert.defaultProps = defaultProps;
export { Alert };