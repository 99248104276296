import { consultar } from 'helpers/api';
import { AppHelper } from 'helpers/AppHelper';

const controller = "cx-planos-contas";

export const CxPlanosContasService = {
	getAll,
	getById,
	create,
	update,
	delete: _delete
};

async function getAll(page) {
	var _url = `/${controller}/getAll/${AppHelper.getEmpresaAtivaId()}.json`
	if(page)_url += `?page=${page}`;

	return await consultar(_url);
}

async function getById(id) {
	return await consultar(`/${controller}/${id}.json`);
}

async function create(params) {
	return await consultar(`/${controller}.json`, 'post', params);
}

async function update(id, params) {
	return await consultar(`/${controller}/${id}.json`, 'put', params);
}

// prefixed with underscored because delete is a reserved word in javascript
async function _delete(id) {
	return await consultar(`/${controller}/${id}.json`, 'delete');
}

// Gerado pelo gerador de SERVICES @felipeshow (17:03:16 15/05/2022)