import { consultar } from 'helpers/api';

const controller = "usuarios-grupos";

export const UsuariosGruposService = {
	getList,
	getAll,
	getById,
	create,
	update,
	delete: _delete
};

async function getList() {
	return await consultar(`/${controller}/list.json`);
}

async function getAll() {
	return await consultar(`/${controller}/all.json`);
}

async function getById(id) {
	return await consultar(`/${controller}/${id}.json`);
}

async function create(params) {
	return await consultar(`/${controller}.json`, 'post', params);
}

async function update(id, params) {
	return await consultar(`/${controller}/${id}.json`, 'put', params);
}

// prefixed with underscored because delete is a reserved word in javascript
async function _delete(id) {
	return await consultar(`/${controller}/${id}.json`, 'delete');
}

// Gerado pelo gerador de SERVICES @felipeshow (23:06:30 12/06/2021)
