import React from 'react';
import Label from './Label'

export default function InputSwitch(props) {

   const { id, label = "", register, switchObj, switchChange, switchLabelOn = 'On', switchLabelOff = 'Off', ...others } = props;

   return <Label label={label} id={id} {...others}>
            <div className="form-check form-switch">
               <input className="form-check-input" onClick={(e) => switchChange(e.target.checked)} type="checkbox" {...register(id)} />
               <label className="form-check-label" >{switchObj ? switchLabelOn : switchLabelOff}</label>
            </div>
         </Label>
}