import React from 'react'
import Label from './Label'

export default function Input(props) {

   const { id, className = 'form-control', isDisabled = false, label = null, type = 'text', value = null, onChange = null, register = null, ...other } = props;
   var _input;

   if(register)
      _input = <input className={className} id={id} name={id} type={type} disabled = {(isDisabled)? "disabled" : ""} {...other} {...register(id)} />
   else
      _input = <input className={className} id={id} name={id} type={type} disabled = {(isDisabled)? "disabled" : ""} value={value} onChange={onChange}  {...other} />

   return   <Label label={label} id={id} {...other}>
               {_input}
            </Label>   
}