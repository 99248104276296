import React, { useEffect, useState } from 'react';

import { Util } from 'helpers/Util';
import { api } from "helpers/api";
import InputSelect from './InputSelect';
import Row from './Row';

export default function InputSelectEstadoCidade(props) {

   const { setValue, estadoDefaultId, cidadeDefaultId, ...other } = props;

   const [estadosOptions, setEstadosOptions] = useState([]);
   const [cidadesOptions, setCidadesOptions] = useState([]);

   const [estadoSelected, setEstadoSelected] = useState(null);
   const [cidadeSelected, setCidadeSelected] = useState(null);

   // Inicio (Checa estados)
   useEffect(() => {
      var estadosStorage = sessionStorage.getItem("estados");

      if (!estadosStorage) {
         // Consultar estados
         console.log('Consultar estados')

         api.get('/estados/list.json')
            .then((response) => {
               if (response.ret_code === 200) {
                  sessionStorage.setItem("estados", JSON.stringify(response.estados))
                  inserirEstados(response.estados)

               } else Util.openError('Ocorreu erro ao consultar Estados..');

            }).catch((error) => Util.openError(`Ocorreu erro ao consultar Estados. ${error}`));
      } else {
         console.log('Estados em localStorage')
         inserirEstados(JSON.parse(estadosStorage));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   // Checa estado default
   useEffect(() => {
      checkEstadoDefault();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [estadosOptions]);

   // Checa estado default
   useEffect(() => {
      checkEstadoDefault();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [estadoDefaultId]);

   // Ao alterar estado (limpa cidades e informa valor)
   useEffect(() => {
      setCidadeSelected(null);

      if (estadoSelected) {
         consultarCidades(estadoSelected.value)
         setValue('estado', estadoSelected.value)
      } else setValue('estado', null)
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [estadoSelected]);

   // Checa cidade default
   useEffect(() => {      
      if (cidadeDefaultId) {
         // Apos alterar os valores da cidades verifica se existe valor padrao para setar
         const opt = cidadesOptions.findIndex((el, i) => (el.value === cidadeDefaultId));
         if (opt >= 0)
            setCidadeSelected(cidadesOptions[opt])
         else setCidadeSelected(cidadesOptions[0])
      }else setCidadeSelected(cidadesOptions[0])
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [cidadesOptions]);

   // Ao alterar cidade (informa valor)
   useEffect(() => {
      if (cidadeSelected) setValue('cidade', cidadeSelected.value)
      else setValue('cidade', null)
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [cidadeSelected]);

   // Verifica estado default
   function checkEstadoDefault() {
      if (estadoDefaultId) {
         // Apos alterar os valores do estados verifica se existe valor padrao para setar
         const opt = estadosOptions.findIndex((el, i) => (el.value === estadoDefaultId));

         if (opt >= 0) setEstadoSelected(estadosOptions[opt])
         else setEstadoSelected(estadosOptions[0])
      }else setEstadoSelected(estadosOptions[0])
   }

   // Insere estados no options
   function inserirEstados(estadosStorage) {
      // Limpa os campos da cidade
      setCidadesOptions([]);

      // Insere os valores em estado
      setEstadosOptions(
         estadosStorage.map(obj => ({ value: obj.id, label: obj.sigla }))
      );
   }

   // Insere cidades no options
   function inserirCidades(cidadesStorage) {
      // Insere os valores em cidade
      setCidadesOptions(
         cidadesStorage.map(obj => ({ value: obj.id, label: obj.nome }))
      )
   }

   // Checa cidades em sessionStorage ou consulta na WEB
   function consultarCidades(estadoId) {
      var cidadesJSON = null;
      var consultar = false;
      var cidadesStorage = sessionStorage.getItem("cidades");

      if (!cidadesStorage) consultar = true;
      else {
         cidadesJSON = JSON.parse(cidadesStorage);

         if (!cidadesJSON[estadoId]) consultar = true;
         else inserirCidades(cidadesJSON[estadoId]);
      }

      if (consultar) {
         console.log(`Consultar cidades ${estadoId}`)

         api.get(`/cidades/list/${estadoId}.json`)
            .then((response) => {
               if (response.ret_code === 200) {
                  if (!cidadesJSON) cidadesJSON = [];

                  // Adicionando cidades ao estado
                  cidadesJSON[estadoId] = response.cidades

                  // Salvando cidades
                  sessionStorage.setItem("cidades", JSON.stringify(cidadesJSON))
                  inserirCidades(response.cidades)

               } else Util.openError('Ocorreu erro ao consultar Cidades..');

            }).catch((error) => Util.openError(`Ocorreu erro ao consultar Cidades. ${error}`));
      } else console.log('Cidades em localStorage')
   }

   return   (<>
               <Row>
                  <InputSelect id="estado" label="Estado" options={estadosOptions} onChange={(e) => setEstadoSelected(e)} value={estadoSelected} {...other} />
               </Row>
               <Row>
                  <InputSelect id="cidade" label="Cidade" options={cidadesOptions} onChange={(c) => setCidadeSelected(c)} value={cidadeSelected} {...other} />
               </Row>
            </>);
}