import React from 'react';

import IntlCurrencyInput from "react-intl-currency-input"

import Label from './Label'

const currencyConfig = {
   locale: "pt-BR",
   formats: {
      number: {
         BRL: {
            style: "currency",
            currency: "BRL",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
         },
      },
   },
};

export default function InputMoney(props) {

   const { id, className = 'form-control', label = null, value = 0.0, onChange, ...others } = props;

   /*
   const handleChange = (event, value, maskedValue) => {
      event.preventDefault();

      //console.log(value);        // value without mask   (ex: 1234.56)
      //console.log(maskedValue);  // masked value         (ex: R$1234,56)

      if(onChange)
         onChange(id, value, maskedValue);
   };
   */

   return (
      <Label label={label} id={id} {...others}>
         <IntlCurrencyInput 
            currency="BRL" config={currencyConfig} 
            id={id} name={id} value={Number(value)}
            className={className} onChange={onChange} {...others} />
      </Label>
   );
}