import React from 'react';

function Loading({msg = null}){
   if(msg == null)msg = 'Carregando...'

   return(
      <div className="text-center p-5">
         <div className="spinner-border spinner-border-lg align-center"></div><br/>{msg}
      </div>
   );
}

export default Loading;