import React from "react";
import ReactDOM from "react-dom";
import App from "./pages/App.jsx";

/**
 *
 * Fazendo
 *
 * A Fazer
 * - Tela contrato -> Ações de Cobrancas (Falta QrCode)
 * - Tela contrato -> Aditivos
 */

/*
   https://code.visualstudio.com/docs/languages/jsconfig

   HELPERS
   https://github.com/fkhadra/react-toastify   
   https://www.npmjs.com/package/sweetalert2-react
   https://www.npmjs.com/package/react-paginate
   https://github.com/AlanWei/react-acl-router

   AXIOS
   https://axios-http.com/ptbr/docs/interceptors

   AUTH
   https://www.digitalocean.com/community/tutorials/how-to-add-login-authentication-to-react-applications
   https://blog.rocketseat.com.br/reactjs-autenticacao/

   MAPA
   https://react-leaflet.js.org/

   ROUTER + AUTH + AXIOS
   https://bilot.group/articles/using-react-router-inside-axios-interceptors/

   Detect router
   https://thewebdev.info/2022/03/07/how-to-detect-route-change-with-react-router/

   React useState Array Update Example
   https://www.notimedad.dev/react-usestate-array-update-example/

   Sharing sessionStorage between tabs
   https://medium.com/@marciomariani/sharing-sessionstorage-between-tabs-5b6f42c6348c
   https://blog.guya.net/2015/06/12/sharing-sessionstorage-between-tabs-for-secure-multi-tab-authentication/

   CRUD
   https://www.bezkoder.com/react-spring-boot-crud/#Source_Code

   Bootstrap
   https://react-bootstrap.github.io/components/buttons/

   PDF
   https://blog.logrocket.com/generating-pdfs-react/

   HTML text format
   https://github.com/remarkablemark/html-react-parser

   Nullish Coalescing
   https://javascript.plainenglish.io/how-to-use-optional-chaining-and-nullish-coalescing-with-typescript-and-react-1d43d0cf9c34

   MommentJS Tutorial
   https://metring.com.br/tutorial-momentjs


   ***** RUN *****
   $npm start

   ***** Atualizando libs *****
   $npm i -g npm-check-updates
   $ncu -u
   $npm install

   ***** Deploy *****
   Sempre verificar o arquivo helpers/AppHelper.jsx
   $npm run build
   $scp -r build/* j2solutions@168.235.103.146:/home/j2solutions/html/j2contratos

   ***** Corrigir F5 -> Not Found *****
   https://www.andreasreiterer.at/fix-browserrouter-on-apache/
*/

ReactDOM.render(<App />, document.getElementById("root"));
