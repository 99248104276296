import React from 'react'
import Select from 'react-select';

import Label from './Label'

export default function InputSelect(props) {

   const { label, id, options, value, onChange, isDisabled = false, ...inputProps } = props;

   function _onChangeLocal(v){
      if(typeof onChange === 'function')
         onChange(v);
   }

   return   <Label label={label} id={id} {...inputProps}>
               <Select isDisabled={isDisabled}  options={options} 
                  value={(typeof value == 'number' && options) ? options.find(item => item.value === value) : value}
                  onChange={_onChangeLocal}/>
            </Label>
}