import React from 'react';

export default function LineTitle({ title = null, dashed = false, mt = 2, mb = 2 }) {

   const line = (dashed) ? <div className="border-dashed-bottom my-3"></div> : <hr className="mb-0 navbar-vertical-divider"/>;

   return(  <div className={`row navbar-vertical-label-wrapper mt-${mt} mb-${mb}`}>
               <div className="col-auto navbar-vertical-label">{title}</div>
               <div className="col ps-0">
                  {line}
               </div>
            </div>);
}