import { consultar } from 'helpers/api';
import { AppHelper } from 'helpers/AppHelper';

const controller = "configuracoes";

export const ConfiguracoesService = {
   getAll,
   save,
   novoToken
}

async function getAll() {
	return await consultar(`/${controller}/getAll/${AppHelper.getEmpresaAtivaId()}.json`);
}

async function save(params) {
	return await consultar(`/${controller}/save/${AppHelper.getEmpresaAtivaId()}.json`, 'put', params);
}

async function novoToken() {
	return await consultar(`/${controller}/novoToken.json`);
}
