import Input from "./Input";
import InputCep from "./InputCep";
import InputCheckbox from "./InputCheckbox";
import InputCnpj from "./InputCnpj";
import InputCpf from "./InputCpf";
import InputDatePicker from "./InputDatePicker";
import InputMoney from "./InputMoney";
import InputPhone from "./InputPhone";
import InputSelect from "./InputSelect";
import InputSelectEstadoCidade from "./InputSelectEstadoCidade";
import InputSwitch from "./InputSwitch";
import InputTextArea from "./InputTextArea";
import Label from "./Label";
import LabelView from "./LabelView";
import Row from "./Row";

const FormControl = {
   Input,
   InputCep,
   InputCheckbox,
   InputCnpj,
   InputCpf,
   InputDatePicker,
   InputMoney,
   InputPhone,
   InputSelect,
   InputSelectEstadoCidade,
   InputSwitch,
   InputTextArea,
   Label,
   LabelView,
   Row,
}

export default FormControl;