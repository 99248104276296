import React from 'react';
import InputMask from 'react-input-mask';
import Label from './Label'
import { Controller } from "react-hook-form";

export default function InputCnpj(props) {

   const { id = "cnpj", label = "CNPJ", control, className = 'form-control', ...others } = props;

   return <Label label={label} id={id} {...others}>
            <Controller
               id={id} name={id} control={control}
               defaultValue=""
               render={({ field }) => ( 
                  <InputMask mask="99.999.999/9999-99" className={className} {...field} />)
               }
            />
         </Label>
}