import React from 'react';
import packageJson from "../../../package.json";

function Footer() {
   return (
      <footer>
         <div className="row g-0 justify-content-between fs--1 mt-3 mb-3">
            <div className="col-12 col-sm-auto text-center">
               <p className="mb-0 text-600">{packageJson.description}<span className="d-none d-sm-inline-block">&nbsp;|&nbsp;</span><br className="d-sm-none" /> 2023 &copy;</p>
            </div>
            <div className="col-12 col-sm-auto text-center">
               <p className="mb-0 text-600">v {packageJson.version}</p>
            </div>
         </div>
      </footer>
   );
}

export { Footer };