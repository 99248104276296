import { useState } from 'react';

const TOKEN_KEY       = "@token-jwt";
const EMPRESA_USUARIO = "@empresa-usuario";
const EMPRESA_ATIVA   = "@empresa-ativa";

function Auth() {
   const [ token, setToken ] = useState(getAuthToken());

   const saveToken = userToken => {
      window.sessionStorage.setItem(TOKEN_KEY, userToken);
      setToken(userToken);
   };

   const deleteToken = () => {
      setToken(null);
      removeAuthTokenReload();
   }

   return { setToken: saveToken, removeToken: deleteToken, token }
}

function updateToken(newToken){
   window.sessionStorage.setItem(TOKEN_KEY, newToken);
}
function getAuthToken(){
   return window.sessionStorage.getItem(TOKEN_KEY);
}

/// Empresa do Usuario
function saveEmpresaUsuario(empresasJson){
   window.sessionStorage.setItem(EMPRESA_USUARIO, empresasJson);
}
function getEmpresaUsuario(){
   return window.sessionStorage.getItem(EMPRESA_USUARIO);
}
function getEmpresaUsuarioJson(){
   return JSON.parse(window.sessionStorage.getItem(EMPRESA_USUARIO));
}

// Empresa Ativa
function setEmpresaAtiva(empAtivaJson){
   window.sessionStorage.setItem(EMPRESA_ATIVA, empAtivaJson)
}
function setEmpresaAtivaObj(obj){
   setEmpresaAtiva(JSON.stringify({id: obj.id, nome: obj.nome, empresa_pai_id: obj.empresa_pai_id, revenda: obj.revenda}))
}
function getEmpresaAtiva(){
   return window.sessionStorage.getItem(EMPRESA_ATIVA);
}
function getEmpresaAtivaJson(){
   return JSON.parse(window.sessionStorage.getItem(EMPRESA_ATIVA));
}

//const getEmpresaAtivaJson2 = () => JSON.parse(window.sessionStorage.getItem(EMPRESA_ATIVA));

// Limpar Sessao
function removeAuthTokenReload(){
   // Limpando sessao
   window.sessionStorage.removeItem(TOKEN_KEY);
   window.sessionStorage.removeItem(EMPRESA_USUARIO);
   window.sessionStorage.removeItem(EMPRESA_ATIVA);

   // Informando as outras abas
   window.localStorage.setItem('CREDENTIALS_FLUSH', Date.now().toString())
   window.localStorage.removeItem('CREDENTIALS_FLUSH')

   // Recarregando pagina
   //window.location.reload();
   window.location.href = "/"
}

// Compartilhar Sessao
function saveSharedSession(tokenShared){
   //window.sessionStorage.setItem(TOKEN_KEY, tokenShared.token)
   window.sessionStorage.setItem(EMPRESA_USUARIO, tokenShared.empresa)
   window.sessionStorage.setItem(EMPRESA_ATIVA, tokenShared.empresaAtiva)
}

export { Auth, updateToken, getAuthToken, removeAuthTokenReload, saveSharedSession, getEmpresaUsuario, getEmpresaUsuarioJson, saveEmpresaUsuario, getEmpresaAtiva, getEmpresaAtivaJson, setEmpresaAtiva, setEmpresaAtivaObj }