import React from 'react'
import Label from './Label'

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import ptBR from 'date-fns/locale/pt-BR';

export default function InputDatePicker(props) {

   const { id, className = 'form-control', isDisabled = false, label = null, value = null, onChange = null, inline = false, monthsShown = 1, register = null, ...other } = props;

   var _input = (register) 
      ? <DatePicker className={className} dateFormat="dd/MM/yyyy" disabled = {(isDisabled)? "disabled" : ""} monthsShown={monthsShown} locale={ptBR} inline={inline} register={register}  />
      : <DatePicker className={className} dateFormat="dd/MM/yyyy" disabled = {(isDisabled)? "disabled" : ""} monthsShown={monthsShown} locale={ptBR} inline={inline} selected={value} onChange={onChange} />

   return   <Label label={label} id={id} {...other}>
               {_input}
            </Label>   
}