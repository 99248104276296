import React from 'react';
import Label from './Label'

export default function InputTextArea(props) {

   const { id, label = null, className = 'form-control', value, rows = 5, onChange, register, ...other } = props;
   
   return   <Label label={label} id={id} {...other}>
               <textarea className={className} id={id} name={id} rows={rows} {...other} {...register(id)}></textarea>
            </Label>
}