import React from 'react';

export default function LabelView(props) {

   const { label = null, line = "true", col = 3 } = props;

   var classLabel = 'col-sm-12 col-form-label';
   var classDiv = 'col-sm-12 align-top';

   if(line === "true"){
      const labelMd = col;
      const divMd = (12 - col);

      classLabel = `col-${labelMd} col-form-label`;
      classDiv = `col-${divMd} align-top`;
   }

   return   (<>
               <div style={{position: 'relative'}}>
                  <label className={classLabel} style={{padding: '0px'}}>{label}</label>
                  <span className={classDiv}>{props.children}</span>
               </div>
            </>);
}