import { consultar, upload } from "helpers/api";
import { AppHelper } from "helpers/AppHelper";

const controller = "empresas";

export const EmpresasService = {
	uploadImg,
	getAll,
	getList,
	getById,
	create,
	update,
	delete: _delete,
	deleteImage,
};

async function uploadImg(file, empresaId) {
	const data = new FormData();
	data.append("file", file, file.name);
	data.append("empresa_id", empresaId);

	return await upload(`/${controller}/newImage.json`, data);
}

async function deleteImage(id) {
	return await consultar(`/${controller}/deleteImage/${id}.json`, "delete");
}

async function getAll(page, busca = null) {
	var _url = AppHelper.mountUrl(`/${controller}/getAll.json`, {
		page: page,
		busca: busca,
	});

	// var _url = `/${controller}/getAll.json`
	// if(page)_url += `?page=${page}`;

	return await consultar(_url);
}

async function getList() {
	return await consultar(`/${controller}/list.json`);
}

async function getById(id) {
	return await consultar(`/${controller}/${id}.json`);
}

async function create(params) {
	return await consultar(`/${controller}/add.json`, "post", params);
}

async function update(id, params) {
	return await consultar(`/${controller}/${id}.json`, "put", params);
}

// prefixed with underscored because delete is a reserved word in javascript
async function _delete(id) {
	return await consultar(`/${controller}/${id}.json`, "delete");
}
// Gerado pelo gerador de SERVICES @felipeshow (22:00:18 05/06/2021)
