
import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

// Toast
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { Util } from 'helpers/Util';

// CSS Modal
import 'react-responsive-modal/styles.css';

// Componentes de layout
import { Menu, Topbar, Footer } from 'components/layout';
import { Alert } from 'components/elements/Alert';

// Auth
import { 
   Auth, 
   removeAuthTokenReload, 
   getAuthToken, 
   saveSharedSession,
   getEmpresaUsuario,
   getEmpresaAtiva
} from 'helpers/auth';

// PAGINA LOGIN
import LoginPage from "./Usuarios/LoginPage";
import Loading from "components/elements/Loading";

// PAGINAS com LazyLoading
const Configuracoes                 = lazy(() => import("./Sistema/Configuracoes"));

const ClientesPage                  = lazy(() => import("./Clientes/_PageManager"));
const CRConfigDuplicata             = lazy(() => import("./Caixa/CrConfigDuplicata/_PageManager"));
const CXDetalhes                    = lazy(() => import("./Caixa/Detalhes/_PageManager"));
const CXPlanosContas                = lazy(() => import("./Caixa/PlanosContas/_PageManager"));
const CXTipoDetalhes                = lazy(() => import("./Caixa/TipoDetalhes/_PageManager"));
const ContratosPage                 = lazy(() => import("./Contratos/_PageManager"));
const ContratosTiposPage            = lazy(() => import("./ContratosTipos/_PageManager"));
const DashboardPage                 = lazy(() => import("./Dashboard/DashboardPage"));
const EmpresasPage                  = lazy(() => import("./Empresas/_PageManager"));
const TestePage                     = lazy(() => import("./Test/index"));
const UsuariosPage                  = lazy(() => import("./Usuarios/_PageManager"));
const UsuariosGruposPage            = lazy(() => import("./UsuariosGrupos/_PageManager"));

export default function App() {
   // Componente de autenticacao
   const { token, setToken } = Auth();

   var userName         = null;
   var userGroupName    = null;
   //var empresa  = null;

   // Listenes de storage para manter login em outras abas
   window.addEventListener('storage', (event) => {
      const _token = getAuthToken();
      console.log('event.key > ' + event.key);

      if(event.key === 'REQUESTING_SHARED_CREDENTIALS' && _token) {
         // Enviando token
         const _empresa      = getEmpresaUsuario()
         const _empresaAtiva = getEmpresaAtiva();
         
         const tokenShare = { token: _token, empresa: _empresa, empresaAtiva: _empresaAtiva }

         window.localStorage.setItem('CREDENTIALS_SHARING', JSON.stringify(tokenShare))
         window.localStorage.removeItem('CREDENTIALS_SHARING')
      }
      if(event.key === 'CREDENTIALS_SHARING' && !_token){
         // Recebendo token de localStorage
         const tokenShared = JSON.parse(event.newValue);
         
         saveSharedSession(tokenShared);
         setToken(tokenShared.token);
      }
      if(event.key === 'CREDENTIALS_FLUSH' && _token){
         removeAuthTokenReload();
      }
   });

   // Checando se token existe. Caso não exista, solicita a outra aba
   var _tokenCheck = getAuthToken();
   if(_tokenCheck == null){
      localStorage.setItem('REQUESTING_SHARED_CREDENTIALS', Date.now());
   }

   // Altera pagina se houver login
   if (!token) {
      return <LoginPage setToken={setToken} />

   }else{
      // Captura dados para cabecalho
      //var empresaJSON = JSON.parse(getEmpresas());
      //empresa           = empresaJSON.nome;

      var jwtJSON       = Util.parseJwt(token);
      userName          = jwtJSON.nome;
      userGroupName     = jwtJSON.gName;
   }

   return (
      <main className="main" id="top">
         <div className="container-fluid" data-layout="container">
            <BrowserRouter>

               <Menu />

               <div className="content">
                  <Topbar name={userName} gName={userGroupName} empresaAtiva={getEmpresaAtiva()} clickSair={removeAuthTokenReload} />

                  <Alert />
                  <ToastContainer />

                  <Suspense fallback={<Loading/>}>
                     <Switch>
                     
                        <Route path="/configuracoes" component={Configuracoes} />
                        <Route path="/clientes" component={ClientesPage} />
                        <Route path="/cr-config-duplicata" component={CRConfigDuplicata} />
                        <Route path="/cx-detalhes" component={CXDetalhes} />
                        <Route path="/cx-planos-contas" component={CXPlanosContas} />
                        <Route path="/cx-tipo-detalhes" component={CXTipoDetalhes} />
                        <Route path="/contratos" component={ContratosPage} />
                        <Route path="/contratos-tipos" component={ContratosTiposPage} />
                        <Route path="/empresas" component={EmpresasPage} />
                        <Route exact path="/teste" component={TestePage} />
                        <Route path="/usuarios" component={UsuariosPage} />
                        <Route path="/usuarios-grupos" component={UsuariosGruposPage} />

                        <Route exact path="/home" component={DashboardPage} />
                        <Route exact path="/dashboard" component={DashboardPage} />
                        <Route exact path="/" component={DashboardPage} />

                        <Redirect from="*" to="/" />
                     </Switch>
                  </Suspense>

                  <Footer />
               </div>

            </BrowserRouter>
         </div>
      </main>
   );
}