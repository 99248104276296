import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import LineTitle from 'components/elements/LineTitle';
import { consultar } from 'helpers/api';
import { Util } from 'helpers/Util';
import { getEmpresaUsuarioJson } from 'helpers/auth';

function Menu() {

   var empresaUsuario = getEmpresaUsuarioJson();
   const [ activeLink, setActiveLink ] = useState("");

   useEffect(() => window.initMenu(), []);

   function onChangeMenu(label){
      setActiveLink(label)
      window.collapseMenu();
   }

   function CustomLink({to, label, icon}){
      return (
         <Link to={to} onClick={() => {onChangeMenu(label)}} className={'nav-link ' + (activeLink === label ? "active" : '')}>
            <i className={icon}></i> 
            <span className="nav-link-text ps-1">{label}</span>
         </Link>
      );
   }

   async function clickClearCache(){
      const { data, error } = await consultar('/sistema/limparCache.json');

      if(error)Util.defaultError(error.msg)
      else Util.defaultSuccess(data.msg)
   }

   return (
      <nav className="navbar navbar-light navbar-vertical navbar-expand-xl">

         {/* LOGO */}
         <div className="d-flex align-items-center">
            <div className="toggle-icon-wrapper">
               <button id="navbarVerticalToggle" className="btn navbar-toggler-humburger-icon navbar-vertical-toggle" data-bs-toggle="tooltip" data-bs-placement="left" title="Toggle Navigation"><span className="navbar-toggle-icon"><span className="toggle-line"></span></span></button>
            </div>
            <Link to="/" className="navbar-brand">
               <div className="d-flex align-items-center py-3">
                  <img src={process.env.PUBLIC_URL + "/images/logo_login.png"} alt="Logo Sistema" width="140" className="me-2" />
               </div>
            </Link>
         </div>

         {/* MENU */}
         <div className="collapse navbar-collapse" id="navbarVerticalCollapse">
            <div className="navbar-vertical-content scrollbar">

               {/* LINKS */}
               <ul className="navbar-nav flex-column mb-3" id="navbarVerticalNav">
                  <li className="nav-item">

                     <CustomLink to="/dashboard" label="Main" icon="fas fa-tv"/>

                     <LineTitle title="Cadastros"/>

                     {(empresaUsuario.revenda) &&
                        <CustomLink to="/empresas" label="Empresas" icon="fas fa-store"/>
                     }
                     <CustomLink to="/clientes" label="Clientes" icon="fas fa-users"/>
                     <CustomLink to="/contratos-tipos" label="Tipos de Contratos" icon="fas fa-file-alt"/>
                     <CustomLink to="/contratos" label="Contratos" icon="fas fa-file-signature"/>
                     {/*
                     <CustomLink to="/cx-planos-contas" label="Planos de Contas" icon="fas fa-users"/>
                     <CustomLink to="/cx-tipo-detalhes" label="Tipo Detalhes Caixa" icon="fas fa-users"/>
                     <CustomLink to="/cr-config-duplicata" label="Config Duplicatas" icon="fas fa-users"/>
                     

                     <LineTitle title="Financeiro"/>

                     <CustomLink to="/cobrancas" label="Cobranças" icon="fas fa-file-invoice-dollar"/>
                     <CustomLink to="/cx-detalhes" label="Detalhes" icon="fas fa-users"/>
                     <CustomLink to="/cx-movimentos" label="Movimentos" icon="fas fa-users"/>
                     <CustomLink to="/cx-caixas" label="Caixas" icon="fas fa-users"/>
                     */}
                  </li>

                  <li className="nav-item">
                     <LineTitle title="Sistema"/>
                     <CustomLink to="/configuracoes" label="Configurações" icon="fas fa-dice-d6"/>

                     <a className="nav-link dropdown-indicator" href="#sistema" role="button" data-bs-toggle="collapse" aria-expanded="false" aria-controls="email">
                        <div className="d-flex align-items-center">
                           <span className="nav-link-icon"><span className="fas fa-cogs"></span></span><span className="nav-link-text">Ações Sistema</span>
                        </div>
                     </a>

                     <ul className="nav collapse false" id="sistema">
                        <li className="nav-item">
                           <CustomLink to="/usuarios" label="Usuários" icon="fas fa-user"/>
                        </li>
                        {(empresaUsuario.id === 1) &&
                           <>
                              <li className="nav-item">
                                 <CustomLink to="/usuarios-grupos" label="Grupos de Usuários" icon="fas fa-users"/>
                              </li>
                              <li className="nav-item">
                                 <CustomLink to="/acl" label="ACL" icon="fas fa-key"/>
                              </li>
                              <li className="nav-item">
                                 <Link to="#" onClick={clickClearCache} className="nav-link"><i className="fas fa-trash"></i> <span className="nav-link-text ps-1">Limpar Cache</span></Link>
                              </li>
                           </>
                        }
                     </ul>
                  </li>

               </ul>
            </div>
         </div>
      </nav>);
}

export { Menu };