import React from 'react';
import PropTypes from 'prop-types';

function Topbar({ name, gName, empresaAtiva, clickSair }){

   var _empresaAtiva = JSON.parse(empresaAtiva)

   function clickSairHandler(e){
      e.preventDefault();
      clickSair();
   }

   return(
      <nav className="navbar navbar-light navbar-glass navbar-top navbar-expand" >
         <button className="btn navbar-toggler-humburger-icon navbar-toggler me-1 me-sm-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarVerticalCollapse" aria-controls="navbarVerticalCollapse" aria-expanded="false" aria-label="Toggle Navigation"><span className="navbar-toggle-icon"><span className="toggle-line"></span></span></button>

         {/* LOGO */}
         <a className="navbar-brand me-1 me-sm-3" href="../index.html">
            <div className="d-flex align-items-center">
               <img src={process.env.PUBLIC_URL + "/images/logo_login.png"} alt="Logo Sistema" width="140" className="me-2" />
            </div>
         </a>

         {/* SEARCH */}
         {/*
         <ul className="navbar-nav align-items-center d-none d-lg-block">
            <li className="nav-item">
               <div className="search-box">

                  <form className="position-relative" data-bs-toggle="search" data-bs-display="static">
                     <input className="form-control search-input fuzzy-search" type="search" placeholder="Pesquisar..." aria-label="Search" />
                     <span className="fas fa-search search-box-icon"></span>
                  </form>
                  <button className="btn-close position-absolute end-0 top-50 translate-middle shadow-none p-1 me-1 fs--2" type="button" data-bs-dismiss="search"></button>

               </div>
            </li>
         </ul>
         */}

         <ul className="navbar-nav navbar-nav-icons ms-auto flex-row align-items-center">
            {/* CART */}
            {/*
            <li className="nav-item">
               <a className="nav-link px-0 notification-indicator notification-indicator-warning notification-indicator-fill icon-indicator" href="../app/e-commerce/shopping-cart.html"><span className="fas fa-shopping-cart" data-fa-transform="shrink-7" style={{fontSize: "33px"}}></span><span className="notification-indicator-number">1</span></a>
            </li>
            */}


            {/* NOTIFICATIONS */}
            {/*
            <li className="nav-item dropdown">
               <a className="nav-link notification-indicator notification-indicator-primary px-0 icon-indicator" id="navbarDropdownNotification" href="/#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span className="fas fa-bell" data-fa-transform="shrink-6" style={{fontSize: "33px"}}></span></a>
               <div className="dropdown-menu dropdown-menu-end dropdown-menu-card" aria-labelledby="navbarDropdownNotification">
                  <div className="card card-notification shadow-none">
                     <div className="card-header">
                        <div className="row justify-content-between align-items-center">
                           <div className="col-auto">
                              <h6 className="card-header-title mb-0">Notifications</h6>
                           </div>
                           <div className="col-auto"><a className="card-link fw-normal" href="#!">Mark all as read</a></div>
                        </div>
                     </div>
                     <div className="scrollbar-overlay" style={{maxHeight: "19rem"}}>
                        <div className="list-group list-group-flush fw-normal fs--1">
                           <div className="list-group-title border-bottom">NEW</div>
                           <div className="list-group-item">
                              <a className="notification notification-flush notification-unread" href="#!">
                                 <div className="notification-avatar">
                                    <div className="avatar avatar-2xl me-3">
                                       <img src="images/logo192.png" className="rounded-circle" alt=""/>
                                    </div>
                                 </div>
                                 <div className="notification-body">
                                    <p className="mb-1"><strong>Emma Watson</strong> replied to your comment : "Hello world"</p>
                                    <span className="notification-time"><span className="me-2" role="img" aria-label="Emoji">💬</span>Just now</span>
                                 </div>
                              </a>
                           </div>
                           <div className="list-group-item">
                              <a className="notification notification-flush notification-unread" href="#!">
                                 <div className="notification-avatar">
                                    <div className="avatar avatar-2xl me-3">
                                       <div className="avatar-name rounded-circle"><span>AB</span></div>
                                    </div>
                                 </div>
                                 <div className="notification-body">
                                    <p className="mb-1"><strong>Albert Brooks</strong> reacted to <strong>Mia Khalifa's</strong> status</p>
                                    <span className="notification-time"><span className="me-2 fab fa-gratipay text-danger"></span>9hr</span>
                                 </div>
                              </a>
                           </div>
                           <div className="list-group-title border-bottom">EARLIER</div>
                           <div className="list-group-item">
                              <a className="notification notification-flush" href="#!">
                                 <div className="notification-avatar">
                                    <div className="avatar avatar-2xl me-3">
                                       <img src="images/logo192.png" className="rounded-circle" alt=""/>
                                    </div>
                                 </div>
                                 <div className="notification-body">
                                    <p className="mb-1">The forecast today shows a low of 20&#8451; in California. See today's weather.</p>
                                    <span className="notification-time"><span className="me-2" role="img" aria-label="Emoji">🌤️</span>1d</span>
                                 </div>
                              </a>
                           </div>
                           <div className="list-group-item">
                              <a className="border-bottom-0 notification-unread  notification notification-flush" href="#!">
                                 <div className="notification-avatar">
                                    <div className="avatar avatar-xl me-3">
                                       <img src="images/logo192.png" className="rounded-circle" alt=""/>
                                    </div>
                                 </div>
                                 <div className="notification-body">
                                    <p className="mb-1"><strong>University of Oxford</strong> created an event : "Causal Inference Hilary 2019"</p>
                                    <span className="notification-time"><span className="me-2" role="img" aria-label="Emoji">✌️</span>1w</span>
                                 </div>
                              </a>
                           </div>
                           <div className="list-group-item">
                              <a className="border-bottom-0 notification notification-flush" href="#!">
                                 <div className="notification-avatar">
                                    <div className="avatar avatar-xl me-3">
                                       <img src="images/logo192.png" className="rounded-circle" alt=""/>
                                    </div>
                                 </div>
                                 <div className="notification-body">
                                    <p className="mb-1"><strong>James Cameron</strong> invited to join the group: United Nations International Children's Fund</p>
                                    <span className="notification-time"><span className="me-2" role="img" aria-label="Emoji">🙋‍</span>2d</span>
                                 </div>
                              </a>
                           </div>
                        </div>
                     </div>
                     <div className="card-footer text-center border-top"><a className="card-link d-block" href="../app/social/notifications.html">View all</a></div>
                  </div>
               </div>
            </li>
            */}

            {/* USER */}
            <ul className="navbar-nav align-items-center d-none2 d-lg-block ms-2">
               <li className="nav-item text-end">
                  {_empresaAtiva.nome}<br/><em>{name}</em>
               </li>
            </ul>
            <li className="nav-item dropdown">
               <a className="nav-link pe-0" id="navbarDropdownUser" href="/#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <div className="avatar avatar-xl">
                     <img src={process.env.PUBLIC_URL + "/images/logo192.png"} className="rounded-circle" alt=""/>
                  </div>
               </a>
               <div className="dropdown-menu dropdown-menu-end py-0" aria-labelledby="navbarDropdownUser">
                  <div className="bg-white dark__bg-1000 rounded-2 py-2">
                     <div className="dropdown-item"><i className="fa fa-user"></i> {name} ({gName})</div>

                     <div className="dropdown-divider"></div>

                     {/*<a className="dropdown-item" href="../pages/user/settings.html">Settings</a>*/}
                     <a className="dropdown-item text-danger" onClick={clickSairHandler} href="../pages/user/settings.html">Sair</a>
                  </div>
               </div>
            </li>

         </ul>

      </nav>);
}

Topbar.propTypes = {
   clickSair: PropTypes.func.isRequired
}

export { Topbar };