import { consultar } from 'helpers/api';

const controller = "cx-tipo-detalhes";

export const CxTipoDetalhesService = {
	list,
	getAll,
	getById,
	create,
	update,
	delete: _delete
};

async function list() {
	return await consultar(`/${controller}/list.json`);
}

async function getAll() {
	return await consultar(`/${controller}/getAll.json`);
}

async function getById(id) {
	return await consultar(`/${controller}/view/${id}.json`);
}

async function create(params) {
	return await consultar(`/${controller}/add.json`, 'post', params);
}

async function update(id, params) {
	return await consultar(`/${controller}/edit/${id}.json`, 'put', params);
}

// prefixed with underscored because delete is a reserved word in javascript
async function _delete(id) {
	return await consultar(`/${controller}/delete/${id}.json`, 'delete');
}

// Gerado pelo gerador de SERVICES @felipeshow (21:03:43 11/06/2021)
