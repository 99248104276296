import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import validator from 'validator';
import PropTypes from 'prop-types';

import FormControl from 'components/elements/form/FormControl';

import { UsuariosService } from 'services';
import { Util } from 'helpers/Util'

import packageJson from "../../../package.json";

import { saveEmpresaUsuario, setEmpresaAtivaObj } from 'helpers/auth';

function ButtonSpinner({loading = false, ...props}){
   return(
      <button className="btn btn-primary btn-danger btn-block mt-2 mb-1" type="submit" disabled={loading} {...props}>
         {/*loading && <i className='fas fa-circle-notch fa-spin'/>*/} Login
      </button>
   );
}

function LoginPage({ setToken }) {
   const { register, handleSubmit, reset }   = useForm();
   const [ loading, setLoading ]             = useState(false);

   async function onSubmit(dataForm){
      setLoading(true); 

      var _error = null;

      if(!dataForm.email) _error = 'Informe o email de login.';
      else if(!validator.isEmail(dataForm.email))_error = 'Digite um email válido';

      if(!_error && !dataForm.senha)_error = 'Informe a senha de login.';
      else if(!_error && !validator.isLength(dataForm.senha, {min: 6}))_error = 'A senha deve possuir pelo menos 6 caracteres.';

      if(_error){
         Util.openError(_error);

      }else{
         setLoading(true); 
         console.log('CONTINUAR LOGIN');

         // TODO
         //const passwordCript = "4849fdb3374dae5b5f44e14173505d73c05ed285";
         const passwordCript = dataForm.senha;

         try{
            const { data , error } = await UsuariosService.login({'email': dataForm.email, 'senha': passwordCript});

            if(error){
               Util.openError(error.msg)

            }else{
               console.log(data);
               saveEmpresaUsuario(JSON.stringify(data.empresa));
               setEmpresaAtivaObj(data.empresa)
               setToken(data.token);
            }
         
         }catch(error){
            console.log('Erro 2');
            Util.openError(error);
         }
      }
      
      setLoading(false)
      //setTimeout(function(){ setLoading(false)}, 3000);
   }

   return (
      <div className="row flex-center min-vh-100 py-6">
         <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4">

            <div className="text-center mb-4">
               <img src={process.env.PUBLIC_URL + "/images/logo_login.png"} alt="Logo Sistema" width="300" className="me-2" />
            </div>

            <div className="card">
               <div className="card-body p-4">
                  <div className="usuarios form content">

                     <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
                        <fieldset>
                           <legend><small>Informe seu login e senha para acessar o sistema.</small></legend>

                           <FormControl.Row>
                              <FormControl.Input register={register} col="9" label="Email" id="email" />
                           </FormControl.Row>
                           <FormControl.Row>
                              <FormControl.Input register={register} col="9" label="Senha" id="senha" type="password" autoComplete="true" />
                           </FormControl.Row>
                        </fieldset>

                        <div className="d-grid gap-2">
                           <ButtonSpinner loading={loading} />
                        </div>
                     </form>

                  </div>
                  <small>v {packageJson.version}</small>
               </div>
            </div>

         </div>
      </div>
   );
}

LoginPage.propTypes = {
   setToken: PropTypes.func.isRequired
}

export default LoginPage;
