import Swal from "sweetalert2";
import { toast } from 'react-toastify';
import moment from "moment";

export const Util = {
   defaultSuccess,
   defaultError,
   defaultWarning,
   defaultInfo,

   openSuccess,
   openError,
   openWarning,
   openInfo,

   openConfirm,

   toastSuccess,
   toastError,
   toastWarning,
   toastInfo,

   getDateBD,
   getDate,
   getDateTime,
   getDateTimeBD,
   formatDateBD,
   formatDate,
   formatDateTime,
   formatDateTimeBD,

   diffDate,
   
   parseJwt,

   converterValorParaReal,
   converterRealParaValor,

   getRandomNumber,

   isNumeric,
   isValidURL,

   listToOptions,

   copyClipboard,
};

function defaultSuccess(msg, title = null) {
   toastSuccess(msg)
}
function defaultError(msg, title = null) {
   toastError(msg)
}
function defaultWarning(msg, title = null) {
   toastWarning(msg)
}
function defaultInfo(msg, title = null) {
   toastInfo(msg)
}

function openSuccess(title, msg) {
   _openAlert(title, msg, 'success')
}
function openError(title, msg) {
   _openAlert(title, msg, 'error')
}
function openWarning(title, msg) {
   _openAlert(title, msg, 'warning')
}
function openInfo(title, msg) {
   _openAlert(title, msg, 'info')
}
function _openAlert(title, msg, icon) {
   Swal.fire({
      title: title,
      text: msg,
      icon: icon,
      showCancelButton: false,
      confirmButtonText: 'Fechar'
   });
}

function openConfirm({ title, msg, confirmCallback, cancelCallback }) {
   Swal.fire({
      title: title,
      text: msg,
      icon: 'warning',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não'
   }).then((result) => {
      if (result.isConfirmed) {
         if (typeof confirmCallback == 'function')
            confirmCallback()
      } else if (result.dismiss === Swal.DismissReason.cancel) {
         if (typeof cancelCallback == 'function')
            cancelCallback()
      }
   })
}

function toastSuccess(msg) {
   toast.success(msg);
}
function toastError(msg) {
   toast.error(msg);
}
function toastWarning(msg) {
   toast.warning(msg);
}
function toastInfo(msg) {
   toast.info(msg);
}

function getDate(sumDays = 0, retObj = false){
   return _momentDateTime(null, sumDays, retObj);
}
function getDateBD(sumDays = 0, retObj = false){
   return _momentDateTime(null, sumDays, retObj, true);
}
function getDateTime(sumDays = 0, retObj = false){
   return _momentDateTime(null, sumDays, retObj, false, true);
}
function getDateTimeBD(sumDays = 0, retObj = false){
   return _momentDateTime(null, sumDays, retObj, true, true);
}
function formatDate(dateTime = null, sumDays = 0, retObj = false) {
   return _momentDateTime(dateTime, sumDays, retObj) 
}
function formatDateBD(dateTime = null, sumDays = 0, retObj = false) {
   return _momentDateTime(dateTime, sumDays, retObj, true) 
}
function formatDateTime(dateTime, sumDays = 0, retObj = false) {
   return _momentDateTime(dateTime, sumDays, retObj, false, true)
}
function formatDateTimeBD(dateTime, sumDays = 0, retObj = false) {
   return _momentDateTime(dateTime, sumDays, retObj, true, true)
}
function _momentDateTime(dateTime = null, sumDays = 0, retObj = false, bd = false, time = false){
   var _date = moment();

   if(dateTime != null && typeof dateTime === 'string'){
      var typeIn = (dateTime.includes("-")) ? 'YYYY-MM-DD' : 'DD/MM/YYYY';
      if(time)typeIn += ' HH:mm:ss'

      _date = moment(dateTime, typeIn)
   }else if(dateTime != null && typeof dateTime === 'object')
      _date = moment(dateTime);

   if(sumDays > 0)_date.add(sumDays, 'days');

   if(retObj)return _date;

   var typeOut = (bd) ? 'YYYY-MM-DD' : 'DD/MM/YYYY';
   if(time)typeOut += ' HH:mm:ss'

   return _date.format(typeOut)
}

function diffDate(dateTime = null, dateTimeCompare = null){
   var dateIni = _momentDateTime(dateTime, 0, true);
   var dateEnd = (dateTimeCompare == null) ? moment() : getDateBD(dateTimeCompare);

   return dateIni.diff(dateEnd, 'day')
}

function parseJwt(token) {
   var base64Url = token.split('.')[1];
   var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
   var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
   }).join(''));

   return JSON.parse(jsonPayload);
}

function converterValorParaReal(atual, prefix = '') {
   if (atual == null)
      return '';
   atual = parseFloat(atual);
   return (prefix ? (prefix + ' ') : '') + atual.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}
function converterRealParaValor(atual) {
   if (atual === null || atual === '')
      return 0;

   atual = atual.replace('R$', '').replace(' ', '').replace('%', '');

   if (isNaN(atual))
      atual = atual.split('.').join('').split(',').join('.');
   return parseFloat(atual);
}

function getRandomNumber(min, max) {
   return Math.random() * (max - min) + min;
}

function isNumeric(value) {
   return /^-?\d+$/.test(value);
}

function isValidURL(str) {
   var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
     '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
     '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
     '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
     '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
     '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
   
   return !!pattern.test(str);
}

function listToOptions(o){	
   var r = [];

   Object.entries(o).forEach(([key, value]) => {
      r.push({ value: parseInt(key), label: value });
   });
   return r;
}

function copyClipboard(txt){
   navigator.clipboard.writeText(txt)
}