import axios from "axios";
import { updateToken, getAuthToken, removeAuthTokenReload } from "./auth";
import { AppHelper } from "./AppHelper";

const api = axios.create({
	baseURL: AppHelper.getUrlRest(),
});

api.interceptors.request.use(async (config) => {
	const token = getAuthToken();

	if (token) config.headers.Authorization = `Bearer ${token}`;

	return config;
});

api.interceptors.response.use(function (response) {
	if (response.config.url !== "/usuarios/login.json") {
		if (
			response.data.jwt_cod === 480 ||
			response.data.jwt_cod === 481 ||
			response.data.jwt_cod === 482
		) {
			removeAuthTokenReload();

			return Promise.reject(response.data);
		}
	}

	return response.data;
});

async function consultar(url, method = "get", params) {
	console.debug("[debug] API Consultar", url);
	var data, pagination, error;

	try {
		// Realizando consulta
		var dataAux = await api[method](url, params);
		//console.log(dataAux);

		if (dataAux._pagination) pagination = dataAux._pagination;
		if (dataAux.return) dataAux = dataAux.return;

		if (dataAux.new_jwt) {
			console.log("Novo TOKEN");
			updateToken(dataAux.new_jwt);
		}

		if (
			dataAux.ret_code === 480 ||
			dataAux.ret_code === 481 ||
			dataAux.ret_code === 482
		) {
			throw Error(dataAux.ret_msg);
		} else if (dataAux.ret_code === 200 || dataAux.ret_code === 202) {
			data = dataAux;
		} else error = { msg: dataAux.ret_msg ?? "Erro não definido." };

		// if(dataAux.ret_code === 200){
		// 	data = dataAux;
		// }else error = { msg: dataAux.ret_msg ?? 'Erro não definido.' };
	} catch (e) {
		console.log(e.message);
		error = { msg: e.message };

		//if(e.response.status === 401){
		// Unauthorized
		removeAuthTokenReload();
		//}
	}

	return { data, pagination, error };
}

async function upload(url, params) {
	var data, error;
	console.debug("[debug] API upload", url);

	try {
		var dataAux = await api.post(url, params, {
			headers: {
				accept: "application/json",
				"Accept-Language": "pt-BR,en;q=0.8",
				"Content-Type": "multipart/form-data;",
			},
		});

		if (
			dataAux.ret_code === 480 ||
			dataAux.ret_code === 481 ||
			dataAux.ret_code === 482
		) {
			throw new Error(dataAux.ret_msg);
		} else if (dataAux.ret_code === 200 || dataAux.ret_code === 202) {
			data = dataAux;
		} else error = { msg: dataAux.ret_msg ?? "Erro não definido." };

		// if(dataAux.success){
		// 	data = dataAux;
		// }else error = { msg: dataAux.msg ?? 'Erro não definido.' };
	} catch (e) {
		//console.log(e);
		error = { msg: e.message };

		//if(e.response.status === 401){
		// Unauthorized
		removeAuthTokenReload();
		//}
	}

	return { data, error };
}

export { api, consultar, upload };
