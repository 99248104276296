import { getEmpresaAtiva } from "./auth";

export const AppHelper = {
	getEmpresaAtivaId,
	getUrlRest,
	mountUrl,
	getPeriodoOptions,
};

function getEmpresaAtivaId() {
	return JSON.parse(getEmpresaAtiva()).id;
}

function getUrlRest(suffix = "") {
	//return `http://localhost/sistema_contratos_rest${suffix}`;
	return `https://contratos.rest.j2solutions.com.br${suffix}`;
}

function mountUrl(url, props) {
	var params = [];
	if (props.page) params.push(`page=${props.page}`);
	if (props.busca) params.push(`busca=${props.busca}`);

	if (params.length === 0) return url;

	return `${url}?${params.join("&")}`;
}

function getPeriodoOptions() {
	return [
		{ value: 1, label: "1 mês" },
		{ value: 3, label: "3 meses" },
		{ value: 6, label: "6 meses" },
		{ value: 12, label: "12 meses" },
		{ value: 18, label: "18 meses" },
		{ value: 24, label: "24 meses" },
	];
}
