import React from 'react';

export default function Row(props) {

   const marginBottom = (props.mb) ? 'mb-' + props.mb : 'mb-3';
   
   return(
      <div className={`row text ${marginBottom}`}>
         {props.children}
      </div>
   );
}